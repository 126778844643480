<template>
  <div>
    <div id="bmPlayer"></div>
  </div>
</template>

<script>
import { Player } from 'bitmovin-player';
import { mapMutations } from 'vuex';

export default {
  name: "VrTest",
  props: ['opt'],
  data() {
    return {
      player: false,
      container: false,
      playerConf: {
        key: 'b3cdbd3e-4dc0-4809-a38d-36f78f7d4b40',
        source: {
          title: "Bitmovin Player",
          description: "VR/360° Example, contentType: single, startPosition: 0, initialRotation: true, initialRotateRate: 0.07",
          poster: '//bitmovin-a.akamaihd.net/content/vr-player/playhouse-vr/poster.jpg',
        }
      },
    }
  },
  methods: {
    ...mapMutations(['appReady']),
    createPlayer() {
      this.container = document.getElementById('bmPlayer');
      this.player = new Player(this.container, this.playerConf);
      const loadObj = {
        title: '',
        description: '',
        hls: this.opt ? 'https://bitmovin-a.akamaihd.net/content/vr-player/playhouse-vr/m3u8s/105560.m3u8' : 'https://l.alivemusic.tv/205FfC',
        vr: {
          startPosition: 0,
          contentType: 'single',
          initialRotation: 'true',
          initialRotateRate: 0.07
        },
      };
      this.player.load(loadObj);
    },
  },
  mounted() {
    this.appReady(true);
    this.createPlayer();

  },
}
</script>

<style scoped>

</style>
